let timer;

function openSubMenu(menuItem) {
    const sub_menu = menuItem.querySelector('.sub-menu');
    const sub_menu_toggle = menuItem.querySelector('.dropdown-toggle');

    menuItem.classList.add('toggled');

    sub_menu.setAttribute('aria-expanded', "true");
    sub_menu.classList.add('toggled');

    sub_menu_toggle.setAttribute('aria-expanded', "true");
    sub_menu_toggle.classList.add('toggled');
}

function closeSubMenu(menuItem) {
    const sub_menu = menuItem.querySelector('.sub-menu');
    const sub_menu_toggle = menuItem.querySelector('.dropdown-toggle');

    menuItem.classList.remove('toggled');

    sub_menu.setAttribute('aria-expanded', "false");
    sub_menu.classList.remove('toggled');

    sub_menu_toggle.setAttribute('aria-expanded', "false");
    sub_menu_toggle.classList.remove('toggled');
}

function toggleSubMenu(e) {
    e.stopPropagation();
    const menuItem = e.currentTarget.closest('.menu-item');
    const subMenu = menuItem.querySelector('.sub-menu');
    const toggled = document.querySelectorAll('#site-menu .toggled');

    if(menuItem.classList.contains('toggled')){
        menuItem.classList.remove('toggled');
    }else{
        for (let i = 0; i < toggled.length; i++) {
            toggled[i].classList.remove('toggled');
        }
        menuItem.classList.add('toggled');
    }

    if(subMenu.classList.contains('toggled')){ //If the menu is already toggled...
        e.target.classList.remove('toggled');
        e.target.setAttribute('aria-expanded', "false");
        subMenu.classList.remove('toggled');
    }else{ // If the submenu isnt toggled...
        for (let i = 0; i < toggled.length; i++) {
            toggled[i].classList.remove('toggled');
        }
        e.target.classList.add('toggled');
        e.target.setAttribute('aria-expanded', "true");
        subMenu.classList.add('toggled');
    }
}

function mouseoverToggle(e) {
    
        const menuItem = this;
        const toggled = document.querySelectorAll('#site-menu .toggled');
        for (let i = 0; i < toggled.length; i++) {
            toggled[i].classList.remove('toggled');
        }
        openSubMenu(menuItem);
        clearTimeout(timer); //reset the timeout used below on mouseout
    
}

function mouseoutToggle(e) {
    const menuItem = this;
    timer = setTimeout(function(){ //Set a timeout to accomodate any mouse inaccuracies from users
        closeSubMenu(menuItem);
        const toggled = document.querySelectorAll('#site-menu .toggled');
        for (let i = 0; i < toggled.length; i++) {
          toggled[i].classList.remove('toggled');
        }
    }, 650);
}


const menuItemsWithChildren = document.querySelectorAll('.menu-item.has-children');
for (let i = 0; i < menuItemsWithChildren.length; i++) {
    const dropdownToggle = menuItemsWithChildren[i].querySelector('.dropdown-toggle');
    dropdownToggle.addEventListener("click", toggleSubMenu, false);

    if (window.innerWidth > 950){
        menuItemsWithChildren[i].addEventListener("mouseover", mouseoverToggle, false);
        menuItemsWithChildren[i].addEventListener("mouseout", mouseoutToggle, false);
    }
    window.addEventListener("resize", function() {
        if (window.innerWidth > 950){
            menuItemsWithChildren[i].addEventListener("mouseover", mouseoverToggle, false);
            menuItemsWithChildren[i].addEventListener("mouseout", mouseoutToggle, false);
        } else if (window.innerWidth < 950){
            menuItemsWithChildren[i].removeEventListener("mouseover", mouseoverToggle, false);
            menuItemsWithChildren[i].removeEventListener("mouseout", mouseoutToggle, false);
        }
    });
}

//Listen for clicks outside of the toggled submenu, and close the menu if found
document.addEventListener('click', function handleClickOutsideBox(event) {
    const openMenuItem = document.querySelector('.menu-item.toggled');
    if (typeof(openMenuItem) != 'undefined' && openMenuItem != null) {
        if (!openMenuItem.contains(event.target)) {
            openMenuItem.querySelector('.dropdown-toggle.toggled').setAttribute('aria-expanded', "false");
            openMenuItem.querySelector('.dropdown-toggle.toggled').classList.remove('toggled');
            openMenuItem.classList.remove('toggled');
            openMenuItem.querySelector('.sub-menu').classList.remove('toggled');
        }
    }
});


const mobileMenuToggle = document.querySelector('#mobile-menu-toggle');
if (typeof(mobileMenuToggle) != 'undefined' && mobileMenuToggle != null) {
    mobileMenuToggle.addEventListener("click" , openMobileMenu , false);
}

function openMobileMenu(event) {
    let docBody = document.body;
    let toggle = event.currentTarget;
    let siteMenu = document.querySelector('#site-nav');
    let toggleIcon = toggle.querySelector('i');
    docBody.classList.add('overflow-hidden');
    siteMenu.classList.add('toggled');
    toggleIcon.classList.remove('ey--icon-menu');
    toggleIcon.classList.add('ey--icon-play');
    toggle.removeEventListener("click" , openMobileMenu , false);
    toggle.addEventListener("click" , closeMobileMenu , false);
}

function closeMobileMenu(event) {
    let docBody = document.body;
    let toggle = event.currentTarget;
    let siteMenu = document.querySelector('#site-nav');
    let toggleIcon = toggle.querySelector('i');
    docBody.classList.remove('overflow-hidden');
    siteMenu.classList.remove('toggled');
    toggleIcon.classList.remove('ey--icon-play');
    toggleIcon.classList.add('ey--icon-menu');
    toggle.removeEventListener("click" , closeMobileMenu , false);
    toggle.addEventListener("click" , openMobileMenu , false);
}

function closeMainMenu() {
    let docBody = document.body;
    let siteMenu = document.querySelector('#site-nav');
    let toggle = document.querySelector('#mobile-menu-toggle');
    let toggleIcon = toggle.querySelector('i');
    docBody.classList.remove('overflow-hidden');
    siteMenu.classList.remove('toggled');
    toggleIcon.classList.remove('ey--icon-play');
    toggleIcon.classList.add('ey--icon-menu');
    toggle.removeEventListener("click" , closeMobileMenu , false);
    toggle.addEventListener("click" , openMobileMenu , false);
}

window.addEventListener("resize", function() {
    if (window.innerWidth > 950){
        closeMainMenu()
    }
});